import React from 'react';

import {
  EventProvider,
} from './EventContext';

import EventFeedback from './EventFeedback';
import EventRSVP from './EventRSVP';
import EventMainSection from './EventMainSection';
import EventAdminSection from './EventAdminSection';

import styles from './Event.module.scss';

export default function Event({ id, authenticatedUser }) {
  return (
    <EventProvider authenticatedUser={authenticatedUser} eventId={id}>
      <EventFeedback />
      <div class={styles.event}>
        <EventRSVP />
        <EventMainSection />
        <EventAdminSection />
      </div>
    </EventProvider>
  );
}
