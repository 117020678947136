import React from 'react';

import {
  useEventState,
} from './EventContext';

import NewEventAttendeesForm from './NewEventAttendeesForm';
import OpenEventInvitationsTable from './OpenEventInvitationsTable';

const EventAdminSection = () => {
  const state = useEventState();

  if (state.loading) {
    return null;
  }

  if (!state?.authenticatedUser?.canAdministerEvents) {
    return null;
  }

  return (
    <>
      <hr />
      <NewEventAttendeesForm />
      <hr />
      <OpenEventInvitationsTable />
    </>
  );
};

export default EventAdminSection;
