import React from 'react';

import {
  useEventState,
  useEventDispatch
} from './EventContext';

import {
  closeEventInvitation,
  generateAndSendEventInvitation,
  recordAttendance,
} from './restApi';

import styles from './EventAttendees.module.scss';

const EventAttendeesTable = () => {
  const state = useEventState();

  const eventDispatch = useEventDispatch();

  const authenticityToken = document.querySelector("meta[name='csrf-token']").getAttribute("content");

  const onResponse = response => eventDispatch({ type: 'ON_RESPONSE', response });

  const onError = feedback => eventDispatch({ type: 'ON_ERROR', feedback });

  const tableActionByEventAttendeeState = {
    'pending': {
      'text': 'Send Invitation',
      'action': (eventAttendee) => {
        generateAndSendEventInvitation({
          id: state?.event?.id,
          onResponse,
          onError,
          body: {
            authenticity_token: authenticityToken,
            event_attendee_id: eventAttendee.id,
          },
        });
      },
    },
    'invitation_sent': {
      'text': 'Close Invitation',
      'action': (eventAttendee) => {
        closeEventInvitation({
          id: state?.event?.id,
          onResponse,
          onError,
          body: {
            authenticity_token: authenticityToken,
            event_attendee_id: eventAttendee.id,
          },
        });
      },
    },
    'accepted_invitation': {
      'text': 'Record Attendance',
      'action': (eventAttendee) => {
        recordAttendance({
          id: state?.event?.id,
          onResponse,
          onError,
          body: {
            authenticity_token: authenticityToken,
            event_attendee_id: eventAttendee.id,
          },
        });
      },
    },
    'declined_invitation': {
      'text': 'Record Attendance',
      'action': (eventAttendee) => {
        recordAttendance({
          id: state?.event?.id,
          onResponse,
          onError,
          body: {
            authenticity_token: authenticityToken,
            event_attendee_id: eventAttendee.id,
          },
        });
      },
    },
    'invitation_closed': {
      'text': 'Send New Invitation',
      'action': (eventAttendee) => {
        generateAndSendEventInvitation({
          id: state?.event?.id,
          onResponse,
          onError,
          body: {
            authenticity_token: authenticityToken,
            event_attendee_id: eventAttendee.id,
          },
        });
      },
    },
    'attended_event': {
      'text': 'No Action',
      'action': () => {},
    },
  };

  return (
    <section>
      <h2 className={styles.tableTitle}>
        Event Attendees
      </h2>
      {state?.event?.eventAttendees?.length ? (
        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>State</th>
              <th>Invitation Expires At</th>
            </tr>
          </thead>
          <tbody>
            {state?.event?.eventAttendees?.map(eventAttendee => {
              return (
                <tr key={eventAttendee.id}>
                  <td>{eventAttendee.firstName} {eventAttendee.lastName}</td>
                  <td>{eventAttendee.email}</td>
                  <td>{eventAttendee.aasmState}</td>
                  <td>{eventAttendee.openEventInvitation ? (eventAttendee.openEventInvitation.expiresAt) : ("N/A")}</td>
                  <td>
                    <button
                      onClick={() => {
                        tableActionByEventAttendeeState[eventAttendee.aasmState]['action'](eventAttendee);
                      }}
                      className={styles.closeInvitationBtn}
                    >
                      {tableActionByEventAttendeeState[eventAttendee.aasmState]['text']}
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      ) : (
        <p className={styles.noContentMessage}>No event attendees.</p>
      )}
    </section>
  );
};

export default EventAttendeesTable;
