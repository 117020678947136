import React from 'react';

import styles from './Event.module.scss';

const EventLoading = () => {
  return (
    <h3 class={styles.loading}>Loading</h3>
  );
};

export default EventLoading;
