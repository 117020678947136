import React, { useEffect } from 'react';

import {
  useEventState,
  useEventDispatch,
} from './EventContext';

import { getEvent } from './restApi';

import EventLoading from './EventLoading';

import styles from './Event.module.scss';

const EventMainSection = () => {
  const state = useEventState();
  const dispatch = useEventDispatch();

  const localeStartDateTimeString = () => {
    const date = new Date(Date.parse(state.event.startTime));
    const timezonePostfix = `${Intl.DateTimeFormat().resolvedOptions().timeZone} (Your Local Time)`;
    return `${date.toLocaleDateString()} ${date.toLocaleTimeString()} ${timezonePostfix}`;
  }

  const localeEndDateTimeString = () => {
    const date = new Date(Date.parse(state.event.endTime));
    const timezonePostfix = `${Intl.DateTimeFormat().resolvedOptions().timeZone} (Your Local Time)`;
    return `${date.toLocaleDateString()} ${date.toLocaleTimeString()} ${timezonePostfix}`;
  }

  const onResponse = response => dispatch({ type: 'ON_RESPONSE', response });

  const onError = feedback => dispatch({ type: 'ON_ERROR', feedback });

  useEffect(() => {
    getEvent({
      id: state.eventId,
      onResponse,
      onError,
    });
  }, []);

  if (state.loading) {
    return <EventLoading />;
  }

  return (
    <>
      <p class={styles.title}>{state.event.title}</p>
      <img class={styles.img} src={state.event.imagePath} />
      <div class={styles.textContainer}>
        <p class={styles.description}>This is a Gattaca-inspired event hosted by Teddy of Isibute and Lori of Thinkubator Media.</p>
        <p class={styles.description}>We’re going to get our nerd on and talk with panelists about how close we are today to the plot of Gattaca being possible. This unique event will blend the world of science fiction with discussions on bioprivacy and gene editing, inspired by the iconic movie *Gattaca*.</p>
        <p class={styles.description}>It's not required but please try watching Gattaca before the event. It's a great movie! We’re looking forward to you joining us for this fun evening filled with science, genes, friends, and networking!</p>
        <div id={styles.eventDetailsContainer}>
          <h3 class={styles.eventDetailsHeading}>Event Details:</h3>
          <p class={styles.eventDetails}>📅 Date: October 23, 2024</p>
          <p class={styles.eventDetails}>🕕 Time: 6:00 PM - 7:30 PM</p>
          <p class={styles.eventDetails}>📍 Location: WeWork in Central Square (625 Massachusetts Ave, Cambridge MA 02139)</p>
        </div>
        <div id={styles.breakdownContainer}>
          <h3 class={styles.breakdownHeading}>Here’s the event breakdown:</h3>
          <p class={styles.breakdown}>6:00 - 6:15 PM: Arrival & Welcome</p>
          <p class={styles.breakdown}>6:15 - 6:45 PM: Panel Discussion on bioprivacy and gene editing</p>
          <p class={styles.breakdown}>6:45 - 7:00 PM: Gattaca-Inspired Game/Exercise</p>
          <p class={styles.breakdown}>7:00 - 7:30 PM: Open Networking</p>
        </div>
        {/* <p class={styles.startTime}>Start Time: {localeStartDateTimeString()}</p>
        <p class={styles.endTime}>End Time: {localeEndDateTimeString()}</p>
        <p class={styles.ownerName}>Event Organizer: {state.event.owner.name}</p>
        {!!state.event.venue && (<p class={styles.venueName}>{venue.name}</p>)}
        {state.event.virtualVenuePath && (
          <div class={styles.virtualVenueBtnContainer}>
            <button
              class={styles.virtualVenueBtn}
              onClick={() => { window.location.assign(state.event.virtualVenuePath) }}
            >
              Join Video Call
            </button>
          </div>
        )} */}
      </div>
    </>
  );
};

export default EventMainSection;
