import { createContext, useContext, useReducer } from 'react';

const EventStateContext = createContext(null);

const EventDispatchContext = createContext(null);

export function EventProvider({ authenticatedUser, eventId, children }) {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const inviteEmail = urlParams.get('invite_email');
  const inviteCode = urlParams.get('invite_code');

  const initialEventState = {
    authenticatedUser,
    eventId,
    loading: true,
    inviteEmail,
    inviteCode,
    event: null,
    newEventAttendee: {
      firstName: '',
      lastName: '',
      email: '',
    },
    authenticatedEventAttendee: null,
    feedbackMessage: null,
    feedbackVariant: null,
  };

  const [eventState, eventDispatch] = useReducer(
    eventReducer,
    initialEventState
  );

  return (
    <EventStateContext.Provider value={eventState}>
      <EventDispatchContext.Provider value={eventDispatch}>
        {children}
      </EventDispatchContext.Provider>
    </EventStateContext.Provider>
  );
}

export function useEventState() {
  return useContext(EventStateContext);
}

export function useEventDispatch() {
  return useContext(EventDispatchContext);
}

function eventReducer(eventState, action) {
  switch (action.type) {
    case 'ON_RESPONSE': {
      return { ...eventState, ...action.response };
    }
    case 'ON_ERROR': {
      return { ...eventState, ...action.feedback };
    }
    case 'CLEAR_FEEDBACK': {
      const clearedFeedback = {
        feedbackMessage: null,
        feedbackVariant: null
      };

      return { ...eventState, ...clearedFeedback };
    }
    case 'UPDATE_FIRST_NAME': {
      return {
        ...eventState,
        newEventAttendee: {
          ...eventState.newEventAttendee,
          firstName: action.firstName,
        }
      };
    }
    case 'UPDATE_LAST_NAME': {
      return {
        ...eventState,
        newEventAttendee: {
          ...eventState.newEventAttendee,
          lastName: action.lastName,
        }
      };
    }
    case 'UPDATE_EMAIL': {
      return {
        ...eventState,
        newEventAttendee: {
          ...eventState.newEventAttendee,
          email: action.email,
        }
      };
    }
    default: {
      throw Error('Unknown action: ' + action.type);
    }
  }
};
