import React from 'react';

import {
  useEventState,
  useEventDispatch
} from './EventContext';

import {
  addEventAttendee,
} from './restApi';

import styles from './EventAttendees.module.scss';

const NewEventAttendeesForm = () => {
  const state = useEventState();

  const eventDispatch = useEventDispatch();

  const authenticityToken = document.querySelector("meta[name='csrf-token']").getAttribute("content");

  const onResponse = response => eventDispatch({ type: 'ON_RESPONSE', response });

  const onError = feedback => eventDispatch({ type: 'ON_ERROR', feedback });

  return (
    <section>
      <h2 className={styles.tableTitle}>
        Add Event Attendees
      </h2>
      <form id={styles.invitationGenerationForm}>
        <input
          className={styles.input}
          type="text"
          id="firstName"
          placeholder="First Name:"
          onChange={
            (e) => {
              const firstName = e.target.value;
              eventDispatch({ type: 'UPDATE_FIRST_NAME', firstName });
            }
          }
          value={state?.newEventAttendee?.firstName}
        />

        <input
          className={styles.input}
          type="text"
          id="lastName"
          placeholder="Last Name:"
          onChange={
            (e) => {
              const lastName = e.target.value;
              eventDispatch({ type: 'UPDATE_LAST_NAME', lastName });
            }
          }
          value={state?.newEventAttendee?.lastName}
        />

        <input
          className={styles.input}
          type="email"
          id="email"
          placeholder="Email:"
          onChange={
            (e) => {
              const email = e.target.value;
              eventDispatch({ type: 'UPDATE_EMAIL', email });
            }
          }
          value={state?.newEventAttendee?.email}
        />

        <button
          type="button"
          className={styles.generateAndSendInvitationBtn}
          onClick={() => {
            addEventAttendee({
              id: state?.event?.id,
              onResponse,
              onError,
              body: {
                event: state?.event,
                newEventAttendee: state?.newEventAttendee,
                authenticity_token: authenticityToken,
              },
            });
          }}
        >
          Add New Attendee
        </button>
      </form>
    </section>
  );
};

export default NewEventAttendeesForm;
