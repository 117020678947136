import React from 'react';

import {
  useEventState,
  useEventDispatch
} from './EventContext';

import Feedback from '../../Feedback/components/Feedback';

const EventFeedback = () => {
  const eventState = useEventState();

  const eventDispatch = useEventDispatch();

  const clearFeedback = () => {
    eventDispatch({ type: 'CLEAR_FEEDBACK' });
  };

  return (
    <Feedback
      message={eventState?.feedbackMessage}
      variant={eventState?.feedbackVariant}
      closeFeedback={clearFeedback}
    />
  );
};

export default EventFeedback;
