/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import ReactOnRails from 'react-on-rails';

import rootComponentWrapper from '../bundles/Shared/rootComponentWrapper';

import Account from '../bundles/Account/components/Account';
import Discourse from '../bundles/Discourse/components/Discourse';
import DiscourseIndex from '../bundles/DiscourseIndex/components/DiscourseIndex';
import Event from '../bundles/Event/components/Event';
import Events from '../bundles/Event/components/Events';
import Feed from '../bundles/Feed/components/Feed';
import LandingPage from '../bundles/LandingPage/components/LandingPage';
import LegalDocument from '../bundles/Legal/components/LegalDocument';
import LegalIndex from '../bundles/Legal/components/LegalIndex';
import NewAccount from '../bundles/Account/components/NewAccount';
import ResetAccountPassword from '../bundles/Account/components/ResetAccountPassword';
import { default as RestrictedActionIndex } from '../bundles/RestrictedAction/components/Index';
import { default as RestrictedActionShow } from '../bundles/RestrictedAction/components/Show';
import { default as RestrictedActionNew } from '../bundles/RestrictedAction/components/New';
import { default as RestrictedActionUpdate } from '../bundles/RestrictedAction/components/Update';
import { default as RoleEdit } from '../bundles/Role/components/Edit';
import { default as RoleIndex } from '../bundles/Role/components/Index';
import { default as RoleShow } from '../bundles/Role/components/Show';
import { default as RoleNew } from '../bundles/Role/components/New';
import StopEventInvites from '../bundles/Event/components/StopEventInvites';
import UserInvitations from '../bundles/UserInvitations/components/UserInvitations';
import { default as UserIndex } from '../bundles/User/components/Index';
import UserShow from '../bundles/User/components/UserShow';
import VirtualVenue from '../bundles/Event/components/VirtualVenue';

ReactOnRails.register({
  Account: rootComponentWrapper(Account),
  Discourse: rootComponentWrapper(Discourse),
  DiscourseIndex: rootComponentWrapper(DiscourseIndex),
  Event: rootComponentWrapper(Event),
  Events: rootComponentWrapper(Events),
  Feed: rootComponentWrapper(Feed),
  LandingPage: rootComponentWrapper(LandingPage),
  NewAccount: rootComponentWrapper(NewAccount),
  LegalDocument: rootComponentWrapper(LegalDocument),
  LegalIndex: rootComponentWrapper(LegalIndex),
  ResetAccountPassword: rootComponentWrapper(ResetAccountPassword),
  RestrictedActionIndex: rootComponentWrapper(RestrictedActionIndex),
  RestrictedActionNew: rootComponentWrapper(RestrictedActionNew),
  RestrictedActionShow: rootComponentWrapper(RestrictedActionShow),
  RestrictedActionUpdate: rootComponentWrapper(RestrictedActionUpdate),
  RoleEdit: rootComponentWrapper(RoleEdit),
  RoleIndex: rootComponentWrapper(RoleIndex),
  RoleNew: rootComponentWrapper(RoleNew),
  RoleShow: rootComponentWrapper(RoleShow),
  StopEventInvites: rootComponentWrapper(StopEventInvites),
  UserInvitations: rootComponentWrapper(UserInvitations),
  UserIndex: rootComponentWrapper(UserIndex),
  UserShow: rootComponentWrapper(UserShow),
  VirtualVenue: rootComponentWrapper(VirtualVenue),
});
