import React, { useState, useEffect } from 'react';

import Feedback from '../../Feedback/components/Feedback';

import { resetCurrentAccountPassword } from './restApi';

import styles from './ResetAccountPassword.module.scss';

export default function NewAccount() {
  const [passwordResetTokenId, setPasswordResetTokenId] = useState(null);
  const [passwordResetTokenValue, setPasswordResetTokenValue] = useState(null);
  const [password, setPassword] = useState(null);
  const [passwordConfirmation, setPasswordConfirmation] = useState(null);
  const [feedbackMessage, setFeedbackMessage] = useState(null);
  const [feedbackVariant, setFeedbackVariant] = useState(null);

  const clearFeedback = () => {
    setFeedbackMessage(null);
    setFeedbackVariant(null);
  };

  const handlePasswordTextChange = (e) => {
    setPassword(e.target.value);
  };

  const handlePasswordConfirmationTextChange = (e) => {
    setPasswordConfirmation(e.target.value);
  };

  const onSubmit = (e) => {
    e.preventDefault();

    resetCurrentAccountPassword({
      onResponse: (data) => {
        if (data.feedbackVariant === 'success') {
          localStorage.setItem("IsibuteFeedbackMessage", data.message);
          localStorage.setItem("IsibuteFeedbackVariant", "success");
          window.location.assign("/");
        } else {
          setFeedbackMessage(data.error_message);
          setFeedbackVariant("error");
        }
      },
      onError: ({ feedbackMessage, feedbackVariant }) => {
        setFeedbackMessage(feedbackMessage);
        setFeedbackVariant(feedbackVariant);
      },
      body: {
        password_reset_token_id: passwordResetTokenId,
        password_reset_token_value: passwordResetTokenValue,
        new_password: password,
        new_password_confirmation: passwordConfirmation,
        authenticity_token: document.querySelector("meta[name='csrf-token']").getAttribute("content")
      },
    });
  };

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    setPasswordResetTokenId(urlParams.get('password_reset_token_id'));
    setPasswordResetTokenValue(urlParams.get('password_reset_token_value'));
  },[]);

  return (
    <React.Fragment>
      <section id={styles.passwordResetSection}>
        <article>
          <form class={styles.passwordResetForm} onSubmit={onSubmit}>
            <label class={styles.inputLabel} for="new_password">
              New Password
            </label>
            <input
              class={styles.input}
              type="password"
              name="account[password]"
              onChange={handlePasswordTextChange}
            />

            <label class={styles.inputLabel} for="new_password_confirmation">
              New Password confirmation
            </label>
            <input
              class={styles.input}
              type="password"
              name="account[password_confirmation]"
              onChange={handlePasswordConfirmationTextChange}
            />

            <input 
              type="submit"
              name="commit" 
              value="Reset Password"
              class={styles.resetPasswordBtn}
              data-disable-with="Reset Password"
            />
          </form>
        </article>
      </section>
      <Feedback
        message={feedbackMessage}
        variant={feedbackVariant}
        closeFeedback={clearFeedback}
      />
    </React.Fragment>
  );
};
