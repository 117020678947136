import React, { useState, useEffect } from 'react';

import Feedback from '../../Feedback/components/Feedback';

import { stopEventInvites } from './restApi';

import styles from './Event.module.scss';

const StopEventInvites = ({ id }) => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const [pageMessage, setPageMessage] = useState("Loading...");
  const [inviteCode] = useState(urlParams.get('invite_code'));
  const [inviteEmail] = useState(urlParams.get('invite_email'))
  const [feedbackMessage, setFeedbackMessage] = useState(null);
  const [feedbackVariant, setFeedbackVariant] = useState(null);

  const clearFeedback = () => {
    setFeedbackMessage(null);
    setFeedbackVariant(null);
  };

  useEffect(() => {
    stopEventInvites({
      onResponse: (data) => {
        setPageMessage(data.pageMessage);
        setFeedbackMessage(data.feedbackMessage);
        setFeedbackVariant(data.feedbackVariant);
      },
      onError: ({ feedbackMessage, feedbackVariant }) => {
        setPageMessage(data.pageMessage);
        setFeedbackMessage(feedbackMessage);
        setFeedbackVariant(feedbackVariant);
      },
      body: {
        event_id: id,
        invite_email: inviteEmail,
        invite_code: inviteCode,
        authenticity_token: document.querySelector("meta[name='csrf-token']").getAttribute("content")
      },
    });
  },[]);

  return (
    <>
      <div id={styles.stopEventInvitesMessageContainer}>
        <h3 id={styles.stopEventInvitesMessage}>{pageMessage}</h3>
      </div>
      <Feedback
        message={feedbackMessage}
        variant={feedbackVariant}
        closeFeedback={clearFeedback}
      />
    </>
  );
};

export default StopEventInvites;
